$theme-color: #233d63;
$theme-color-2: #F66B5D;
$theme-color-3: #4021ba;
$theme-color-4: #677286;
$theme-color-5: #f4f6fb;
$theme-font: 'Barlow', sans-serif;
$primary-font: 45px;
$default-font: 16px;
$default-font-2: 15px;
$default-font-3: 14px;
$default-font-4: 13px;
$default-font-5: 12px;
$color-light: #fff;
$color-light-2: #a0aec6;
$color-light-grey: #f6f8f9;
$color-dark: #000;
$display: 65px;
$display-2: 20px;
$display-3: 40px;
$display-4: 18px;
$display-5: 22px;
$display-6: 35px;
$display-7: 120px;
$display-8: 58px;
$display-9: 90px;
$display-10: 25px;
$display-11: 55px;
$display-12: 28px;
$display-13: 70px;
$display-14: 30px;
$display-15: 50px;
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$text-height: 35px;
$text-height-2: 28px;
$text-height-3: 30px;
$title-height: 80px;
$title-height2: 65px;
$title-height3: 55px;
$color-1: #eee;
$color-2: #ddd;
$color-3: #cccdce;
$color-4: #fafafa;
$color-5: #473b76;
$color-6: #220d76;
$color-7: #8002D9;
$color-8: #8B62A7;
$color-9: #fceaee;

$alter-color: #182eb2;
$alter-color-2: #31a5ff;
$alter-color-3: #CD3099;
$alter-color-4: #ff2e2e;
$alter-color-5: #D44439;
$alter-color-6: #3B5998;
$alter-color-7: #6AF3C8;

$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device_two: '(max-width: 768px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_and_small: 'only screen and (min-width: 481px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_four: '(max-width: 425px)';
$small_mobile_three: '(max-width: 400px)';
$small_mobile_five: '(max-width: 375px)';
$small_mobile_two: '(max-width: 320px)';


$extra-device: '(max-width: 800px)';
$extra-device2: '(max-width: 500px)';