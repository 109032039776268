/*====================================================
    pagination-wrapper
 ====================================================*/
.pagination-wrapper {
  margin-top: 40px;
}
.pagination-list {
  li {
    display: inline-block;
    text-transform: capitalize;
    @media #{$small_mobile_five} {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.pag__active {
      a {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
    a {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: $theme-color;
      background-color: rgba(35, 61, 99, 0.1);
      @include border-radius(50%);
      @include transition(0.3s);
      font-size: $default-font;
      font-weight: $font-weight-medium;
      &.pag__prev {
        margin-right: 4px;
      }
      &.pag__next {
        margin-left: 4px;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
  }
}
