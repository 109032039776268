/*====================================================
    blog-area
 ====================================================*/
.blog-area {
  padding-top: 106px;
  padding-bottom: 30px;
  @media #{$tab_device} {
    padding-bottom: 10px;
  }
  @media #{$large_mobile} {
    padding-bottom: 10px;
  }
  @media #{$small_mobile_two} {
    padding-bottom: 50px;
  }
  .col-lg-8 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-lg-4 {
    @media #{$tab_device} {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .sec-heading {
    @media #{$large_mobile} {
      text-align: center;
    }
    @media #{$small_mobile} {
      text-align: center;
    }
  }
  .blog-btn.text-right {
    @media #{$tab_device} {
      text-align: left !important;
      margin-bottom: 30px;
    }
    @media #{$large_mobile} {
      text-align: center !important;
      margin-bottom: 30px;
    }
    @media #{$small_mobile} {
      text-align: center !important;
      margin-bottom: 30px;
    }
  }
  .blog-post-wrapper {
    margin-top: 30px;
  }
}
/*====================================================
   blog-area 2
====================================================*/
.blog-area2 {
  padding-top: 40px;
  padding-bottom: 90px;
  .blog-post-item {
    .blog-post-body {
      .theme-button {
        margin-top: 25px;
        color: $color-light;
        line-height: 45px;
        font-size: $default-font-2;
        &:hover {
          color: $color-light;
        }
      }
    }
  }
}

/*====================================================
   case-area
====================================================*/
.case-area {
  padding-top: 114px;
  padding-bottom: 120px;
  .blog-post-wrapper {
    margin-top: 28px;
    .blog-post-item {
      @include transition(0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55));
      .blog-post-img {
        .blog__img {
          @include border-radius(4px 4px 20px 20px);
        }
      }
      .blog-post-body {
        .case__meta {
          font-size: $default-font;
          color: $theme-color-4;
          font-weight: $font-weight-medium;
          text-transform: capitalize;
        }
        .blog__title {
          margin-top: 5px;
          font-size: $display-5;
        }
        .blog__desc {
          font-weight: $font-weight-medium;
          font-size: $default-font;
          margin-top: 22px;
          margin-bottom: 32px;
        }
      }
      &:hover {
        @include transform(translateY(-4px));
      }
    }
  }
}
/*====================================================
   case-area2
====================================================*/
.case-area2 {
  padding-bottom: 43px;
  .sec-heading {
    @media #{$small_mobile} {
      text-align: center;
    }
    .sec__title {
      margin-bottom: 32px;
    }
  }
}

.single-case-slider {
  .owl-dots {
    position: absolute;
    right: 20px;
    bottom: 20px;
    div {
      width: 14px;
      height: 14px;
      border: 2px solid $color-light;
      @include border-radius(50%);
      display: inline-block;
      margin: 0 1px;
      @include transition(0.3s);
      @include transform(scale(0.8));
      &.active,
      &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        @include transform(scale(1));
      }
    }
  }
}

/*========= single-case ==========*/
.single-case {
  padding-top: 118px;
  padding-bottom: 67px;
  .col-lg-4 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .blog-post-wrapper {
    margin-top: 4px;
    .blog-post-item {
      @include box-shadow(0 0 0 0);
      width: auto;
      .blog-post-body {
        padding-left: 0;
        padding-bottom: 0;
        padding-right: 0;
        .blog__title {
          font-size: $display-14;
          margin-top: 0;
          @media #{$small_mobile_four} {
            line-height: 35px;
          }
        }
        .blog__desc {
          margin-bottom: 26px;
        }
        .list-items {
          @media #{$large_mobile} {
            margin-bottom: 35px;
          }
          @media #{$small_mobile} {
            margin-bottom: 35px;
          }
          li {
            color: $theme-color-4;
            font-weight: $font-weight-medium;
            position: relative;
            margin-bottom: 10px;
            padding-left: 0;
            &:after {
              display: none;
            }
            &:last-child {
              margin-bottom: 0;
            }
            i {
              margin-right: 10px;
              color: $theme-color-2;
            }
          }
        }
        .blog__list2 {
          @media #{$tab_device} {
            margin-bottom: 0;
          }
          @media #{$large_mobile} {
            margin-bottom: 0;
          }
          @media #{$small_mobile} {
            margin-bottom: 0;
          }
        }
        .single-case-img-box {
          img {
            width: 100%;
            @include border-radius(4px);
          }
          .blog__title {
            font-size: $display-10;
            margin-top: 0;
            margin-bottom: 18px;
            line-height: 30px;
          }
          .blog__title2 {
            @media #{$large_mobile} {
              margin-top: 35px;
            }
            @media #{$small_mobile} {
              margin-top: 35px;
            }
            &:hover {
              color: $theme-color;
            }
          }
          .blog__desc {
            margin-top: 0;
            margin-bottom: 25px;
          }
        }
        .single-case-img-box2 {
          margin-top: 50px;
          margin-bottom: 40px;
          @media #{$large_mobile} {
            margin-bottom: 0;
          }
          @media #{$small_mobile} {
            margin-bottom: 0;
          }
        }
        .tab-content-shared {
          margin-top: 60px;
          .nav-tabs {
            li {
              @media #{$small_mobile_four} {
                margin-bottom: 40px;
              }
            }
          }
          .tab-content {
            margin-top: 40px;
            @media #{$small_mobile_four} {
              margin-top: 15px;
            }
          }
        }
        .single-chart-box {
          .blog__title {
            font-size: $display-10;
            margin-top: 0;
            line-height: 30px;
            &:hover {
              color: $theme-color;
            }
          }
          .blog__desc {
            margin-top: 18px;
            margin-bottom: 15px;
          }
          .blog__desc2 {
            margin-bottom: 0;
            @media #{$large_mobile} {
              margin-bottom: 40px;
            }
            @media #{$small_mobile} {
              margin-bottom: 40px;
            }
          }
          .result-chart {
            width: 100%;
          }
          .line-legend {
            padding-top: 25px;
            padding-left: 18px;
            .chart-legend {
              li {
                display: inline-block;
                font-size: $default-font;
                text-transform: capitalize;
                position: relative;
                color: $theme-color;
                margin-left: 30px;
                font-weight: $font-weight-medium;
                span {
                  width: 13px;
                  height: 13px;
                  display: inline-block;
                  background-color: $theme-color-3;
                  @include border-radius(30px);
                  position: absolute;
                  left: -20px;
                  top: 6px;
                  &.legend__two {
                    background-color: $theme-color-2;
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        @include transform(translateY(0));
      }
    }
  }
}

/*====================================================
   blog-grid-area
====================================================*/
.blog-grid-area {
  padding-top: 120px;
  padding-bottom: 10px;
  .blog-post-wrapper {
    margin-top: 0;
  }
}

/*====================================================
    inner-blog-area
 ====================================================*/
.post-pagination {
  margin-top: 35px;
  margin-bottom: 25px;
  .prev-post {
    float: left;
  }
  .next-post {
    float: right;
  }
  .post__link {
    font-size: $display-4;
    color: $theme-color;
    font-weight: $font-weight-medium;
    @include transition(0.3s);
    &:hover {
      color: $theme-color-2;
    }
  }
}

.blog-area.inner-blog-area {
  padding-top: 121px;
  padding-bottom: 10px;
  .blog-post-wrapper {
    margin-top: 0;
    .blog-post-item {
      .blog-post-body {
        .post__meta {
          li {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
              margin-right: 5px;
            }
          }
        }
        .blog__title {
          font-size: $display-10;
          border-bottom: 1px solid $color-1;
          padding-bottom: 26px;
        }
        .blog__desc {
          margin-bottom: 30px;
          margin-top: 18px;
        }
      }
    }
  }
}
.blog-single-wrapper {
  .blog-post-item {
    @include box-shadow(0 0 0 0);
    margin-bottom: 0;
    width: auto;
    .blog-post-body {
      padding-top: 25px;
      border: none;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
      .blog__title {
        font-size: $display-12;
      }
      .blog__desc {
        margin-bottom: 17px;
      }
      .single-blog-img-box {
        margin-top: 40px;
        margin-bottom: 37px;
        img {
          width: 100%;
        }
        .list-items {
          li {
            padding-left: 0;
            color: $theme-color-4;
            font-weight: $font-weight-medium;
            i {
              color: $theme-color-2;
              margin-right: 5px;
            }
            &:after {
              display: none;
            }
          }
        }
        .blog__list {
          @media #{$large_mobile} {
            margin-top: 40px;
          }
          @media #{$small_mobile} {
            margin-top: 40px;
          }
        }
      }
    }
    &:hover {
      @include transform(translateY(0));
    }
  }
}
/*====================================================
    blog-single-area
 ====================================================*/
.inner-blog-area.blog-single-area {
  padding-bottom: 40px;
  .col-lg-4 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
