.payment-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: $default-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: $font-weight-medium;
  color: $theme-color-4;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      border-color: $theme-color-2;
    }
    &:checked ~ .checkmark:after {
      @include transform(scale(1));
      opacity: 1;
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    margin: 3px 0 0 0;
    border: 2px solid $color-2;
    @include border-radius(50%);
    @include transition(0.3s);
    &:after {
      top: 2px;
      left: 2px;
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      background-color: $theme-color-2;
      opacity: 0;
      @include transform(scale(0.1));
      @include border-radius(50%);
      @include transition(0.3s);
    }
  }
  &:hover input ~ .checkmark:after {
    @include transform(scale(1));
    opacity: 1;
  }
  &:hover input ~ .checkmark {
    border-color: $theme-color-2;
  }
}