.blog-post-item {
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  margin-bottom: 30px;
  .blog-post-img {
    position: relative;
    @include border-radius(4px 4px 20px  20px);
    .blog__img {
      width: 100%;
      @include border-radius(4px 4px 20px  20px);
    }
    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $color-light;
      @include transform(translateX(-50%));
      z-index: 1;
    }
    .item-overlay {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $theme-color;
      @include border-radius(4px 4px 20px  20px);
      @include transform(scale(0.7));
      opacity: 0;
      visibility: visible;
      @include transition(0.3s);
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        color: $theme-color;
        font-size: $display-4;
        width: 53px;
        height: 53px;
        line-height: 53px;
        text-align: center;
        background-color: $color-light;
        @include border-radius(50%);
        @include transition(0.3s);
        &:after {
          position: absolute;
          content: '';
          left: 50%;
          top: -1px;
          width: 35px;
          height: 13px;
          @include border-radius(50%);
          background-color: $theme-color;
          @include transform(translateX(-50%));
        }
        &:hover {
          background-color: $theme-color-2;
          color: $color-light;
        }
      }
    }
  }
  .blog-post-body {
    padding: 32px 25px 32px 25px;
    @include border-radius(0 0 4px 4px);
    background-color: $color-light;
    .post__meta {
      margin-bottom: 5px;
      li {
        display: inline-block;
        color: $theme-color-4;
        font-size: $default-font-2;
        opacity: .7;
        font-weight: $font-weight-medium;
        text-transform: capitalize;
        a {
          color: $theme-color;
          font-weight: $font-weight-semi-bold;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
    .blog__title {
      font-size: $display-5;
      color: $theme-color;
      font-weight: $font-weight-bold;
      line-height: $text-height-2;
      display: block;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
      @media #{$large_mobile} {
        font-size: $display-5;
      }
      @media #{$small_mobile} {
        font-size: $display-5;
      }
    }
    .blog__desc {
      font-size: $default-font;
      color: $theme-color-4;
      margin-top: 24px;
      margin-bottom: 25px;
      line-height: $text-height-2;
      font-weight: $font-weight-medium;
    }
    .post__bottom {
      li {
        text-transform: capitalize;
        font-size: $default-font;
        color: $theme-color;
        a {
          font-weight: $font-weight-medium;
          color: $theme-color;
          @include transition(0.3s);
          &.blog__btn {
            float: left;
            padding-right: 35px;
            position: relative;
            .fa__arrow {
              position: absolute;
              right: 25px;
              top: 50%;
              @include transform(translateY(-50%));
              font-size: $default-font-3;
              @include transition(0.3s);
            }
            &:hover {
              .fa__arrow {
                right: 22px;
              }
            }
          }

          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
  }
  &:hover {
    .blog-post-img {
      .item-overlay {
        @include transform(scale(1));
        opacity: 1;
        visibility: visible;
      }
    }
  }
}