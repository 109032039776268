@keyframes loader-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*== heartBeat ==*/
@keyframes heartBeat {
  0% {
    @include transform(scale(1));
  }
  25% {
    @include transform(scale(1.1));
  }
  40% {
    @include transform(scale(1));
  }
  60% {
    @include transform(scale(1.04));
  }
  100% {
    @include transform(scale(1));
  }
}