.info-item {
  position: relative;
  background-color: $color-light;
  @include border-radius(20px 0 20px 0);
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  padding: 45px 30px 40px 30px;
  margin-bottom: 30px;
  z-index: 1;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: $theme-color-2;
    left: 0;
    @include border-radius(4px);
    @include transform(scale(0));
    @include transition(0.3s);
  }
  @media #{$tab_device} {
    margin-bottom: 30px;
  }
  @media #{$large_mobile} {
    margin-bottom: 30px;
  }
  @media #{$small_mobile} {
    margin-bottom: 30px;
  }
  .info__number {
    font-size: $display-6;
    position: absolute;
    top: 3px;
    right: 5px;
    width: 60px;
    height: 55px;
    font-weight: $font-weight-semi-bold;
    line-height: 55px;
    color: rgba(35, 61, 92, 0.3);
    @include border-radius(0 0 0 10px);
    z-index: 1;
    @include transform(scale(0));
    @include transition(0.3s);
  }
  .info__icon {
    color: $theme-color-2;
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin-bottom: 30px;
    z-index: 1;
    @include transition(0.3s);
    background-color: rgba(246, 107, 93, 0.1);
    @include border-radius(50%);
    @media #{$laptop_m_four} {
      font-size: $primary-font;
      margin-top: 20px;
      margin-bottom: 35px;
    }
    @media #{$tab_device} {
      font-size: $display;
      margin-bottom: 39px;
    }
    @media #{$large_mobile} {
      font-size: $display;
      margin-bottom: 39px;
    }
    @media #{$small_mobile} {
      font-size: $display;
      margin-bottom: 39px;
    }
    &:before {
      font-size: $primary-font;
    }
    &:after {
      position: absolute;
      content: '';
      left: 50%;
      top: -8px;
      width: 50px;
      height: 25px;
      @include border-radius(50%);
      background-color: $color-light;
      @include transform(translateX(-50%));
    }
  }
  .info__title {
    font-size: $display-2;
    font-weight: $font-weight-bold;
    margin-bottom: 24px;
    text-transform: capitalize;
    a {
      color: $theme-color;
      @include transition(0.3s);
    }
  }
  .info__desc {
    font-size: $default-font;
    color: $theme-color-4;
    line-height: $text-height-2;
    font-weight: $font-weight-medium;
  }
  &:hover {
    @include border-radius(0);
    .info__number {
      @include transform(scale(1));
    }
    .info__icon {
      background-color: $theme-color-2;
      color: $color-light;
    }
    .info__title {
      a {
        color: $theme-color-2;
      }
    }
    &:after {
      @include transform(scale(1));
    }
    &:before {
      opacity: 0.2;
      visibility: visible;
      @include transform(scale(1));
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/dots3.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    @include transition(0.5s);
    @include transform(scale(0.6));
  }
}