/*======================================
        service-area
 ======================================*/
.service-item {
  border: 2px solid $theme-color;
  padding: 53px 30px 38px 30px;
  @include border-radius(20px 0 20px 0);
  @include transition(0.3s);
  .service-icon {
    margin-bottom: 25px;
    .service__icon {
      font-size: $display;
      color: $theme-color-2;
      display: inline-block;
    }
  }
  .service__title {
    font-size: $display-2;
    font-weight: $font-weight-bold;
    margin-bottom: 18px;
    text-transform: capitalize;
    a {
      color: $theme-color;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  .service__desc {
    font-size: $default-font;
    color: $theme-color-4;
    line-height: $text-height-2;
    font-weight: $font-weight-medium;
    margin-bottom: 18px;
  }
  &:hover {
    @include border-radius(0 20px 0 20px);
  }
}
.service-content {
  .owl-dots {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    margin-top: 45px;
    div {
      width: 14px;
      height: 14px;
      border: 1px solid $theme-color;
      @include border-radius(50%);
      display: inline-block;
      margin: 0 3px;
      @include transition(0.3s);
      &:hover,
      &.active {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
      }
    }
  }
}
.service-img-box {
  margin-top: 170px;
  position: relative;
  z-index: 1;
  @include transition(0.3s);
  @media #{$tab_device} {
    margin-top: 60px;
  }
  @media #{$large_mobile} {
    margin-top: 60px;
  }
  @media #{$small_mobile} {
    margin-top: 60px;
  }
  img {
    width: 100%;
    @include border-radius(4px);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  }
  .video-play-btn {
    position: absolute;
    top: 0;
    left: 40px;
    color: $color-light;
    font-size: $display-5;
    padding: 20px 15px 15px 15px;
    text-align: center;
    @include border-radius(0 0 50px 50px);
    background-color: $color-light;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @media #{$small_mobile_four} {
      top: auto;
      bottom: 0;
      left: 0;
      padding: 10px;
      @include border-radius(0 50px 50px 0);
    }
    span {
      color: $theme-color;
      display: block;
      font-weight: $font-weight-medium;
      line-height: 21px;
      @media #{$small_mobile_four} {
        display: inline-block;
      }
    }
    i {
      background-color: $theme-color;
      width: 60px;
      height: 60px;
      line-height: 60px;
      display: inline-block;
      margin-top: 15px;
      @include border-radius(50%);
      @include transition(0.3s);
      position: relative;
      @media #{$small_mobile_four} {
        margin-top: 0;
        margin-left: 15px;
      }
      &:after {
        position: absolute;
        content: '';
        left: 1px;
        bottom: 1px;
        width: 14px;
        height: 14px;
        @include border-radius(50%);
        background-color: $color-light;
      }
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    bottom: -40px;
    left: -37px;
    width: 20%;
    height: 40%;
    background-image: url("../images/dots.png");
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.2;
    @include transition(0.3s);
  }
  &:after {
    bottom: auto;
    top: -50px;
    right: -39px;
    left: auto;
    height: 84%;
    width: 18%;
    @media #{$extra-device} {
      display: none;
    }
  }
  &:hover {
    @include transform(scale(0.99));
    .video-play-btn {
      i {
        background-color: $theme-color-2;
      }
    }
  }
}

.service-area {
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 104px;
  @media #{$tab_device} {
    padding-top: 60px;
    margin-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-top: 60px;
    margin-bottom: 0;
  }
  @media #{$large_mobile_three} {
    padding-top: 40px;
  }
  @media #{$small_mobile} {
    padding-top: 60px;
    margin-bottom: 0;
  }
  .about-item {
    padding-left: 30px;
    @media #{$tab_device} {
      padding-left: 0;
      margin-top: 172px;
    }
    @media #{$large_mobile} {
      padding-left: 0;
      margin-top: 172px;
    }
    @media #{$small_mobile} {
      padding-left: 0;
      margin-top: 152px;
    }
    .sec-heading {
      .sec__desc {
        margin-bottom: 25px;
      }
      .readmore__btn {
        margin-top: 0;
      }
    }
  }
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    background-color: $color-light-grey;
    z-index: -1;
    @media #{$tab_device} {
      height: 1040px;
    }
    @media #{$large_mobile} {
      height: 1040px;
    }
    @media #{$large_mobile_three} {
      height: 950px;
    }
    @media #{$small_mobile} {
      height: 1040px;
    }
  }
  &:before {
    display: none;
  }
}
/*======================================
        service-area2
 ======================================*/
.service-area2 {
  padding-top: 120px;
  @media #{$small_mobile} {
    padding-top: 60px;
  }
}
/*======================================
      service-area 3
 ======================================*/
.service-area3 {
  padding-top: 70px;
  .blog-post-item {
    .theme-button {
      font-size: $default-font-2;
      line-height: 45px;
    }
  }
}