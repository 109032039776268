/*====================================================
    footer-area
 ====================================================*/
.footer-area {
  background-color: $color-light;
  padding-top: 188px;
  padding-bottom: 35px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media #{$small_mobile_two} {
    padding-top: 110px;
  }
  .box-icons {
    position: absolute;
    left: 80px;
    top: 50%;
    @include transform(translateY(-50%));
    z-index: 1;
    @media #{$laptop_l} {
      display: none;
    }
    .box-one {
      position: absolute;
      top: -50px;
      left: 110px;
      width: 100px;
      height: 100px;
      border: 3px solid $color-light-2;
      opacity: 0.3;
    }
    .box-two {
      position: absolute;
      bottom: -145px;
      left: 55px;
      width: 80px;
      height: 80px;
      border: 3px solid $color-light-2;
      opacity: 0.2;
    }
    .box-three {
      position: absolute;
      bottom: -210px;
      left: 25px;
      width: 50px;
      height: 50px;
      border: 3px solid $color-light-2;
      opacity: 0.1;
    }
  }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    z-index: -1;
  }
  &:after {
    position: absolute;
    top: -30px;
    left: 0;
    content: "";
    background-color: $color-light;
    width: 150%;
    height: 130px;
    @include transform(rotate(2deg));
    @media #{$small_mobile_two} {
      height: 70px;
    }
  }
  .col-lg-3 {
    @media #{$tab_device} {
     -ms-flex: 0 0 50%;
     flex: 0 0 50%;
     max-width: 50%;
    }
    &:nth-child(1),
    &:nth-child(2) {
      @media #{$tab_device} {
        margin-bottom: 70px;
      }
    }
  }
}

.footer-shared {
  height: 100%;
  @media #{$tab_device} {
    height: 375px;
  }
  @media #{$large_mobile} {
    margin-bottom: 50px;
    height: 100%;
  }
  @media #{$small_mobile} {
    margin-bottom: 50px;
    height: 100%;
  }
  .footer-title {
    font-size: $display-5;
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    margin-bottom: 40px;
    color: $color-light;
  }
  .footer__desc {
    font-size: $default-font;
    color: $color-light-2;
    font-weight: $font-weight-medium;
    margin-top: 26px;
    line-height: $text-height-2;
  }
  .footer-address-links {
    margin-top: 34px;
    margin-bottom: 35px;
    li {
      font-size: $default-font;
      color: $color-light-2;
      font-weight: $font-weight-medium;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: block;
        color: $color-light-2;
        @include transition(0.3s);
        position: relative;
        padding-left: 45px;
        i {
          width: 35px;
          height: 35px;
          line-height: 35px;
          background-color: rgba(255, 255, 255, 0.1);
          color: $color-light;
          @include border-radius(50%);
          @include transition(0.3s);
          text-align: center;
          position: absolute;
          left: 0;
          top: -5px;
          &:after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: 10px;
            height: 10px;
            @include border-radius(50%);
            background-color: $theme-color;
          }
        }
        &:hover {
          color: $theme-color-2;
          i {
            background-color: $theme-color-2;
          }
        }
      }
    }
  }
  .footer-meta {
    font-size: $display-4;
    color: $color-light;
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
  }
  .social-profile {
    li {
      a {
        color: $color-light;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background-color: $theme-color-4;
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
        &:before {
          display: none;
        }
        &:after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 10px;
          height: 10px;
          @include border-radius(50%);
          background-color: $theme-color;
        }
        &:hover {
          color: $color-light;
          background-color: $theme-color-2;
        }
      }
    }
  }
  .company-link {
    li {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
      a {
        position: relative;
        width: auto;
        height: auto;
        display: inline-block;
        background-color: transparent;
        @include box-shadow(0 0 0 0);
        @include border-radius(0 0 0 0);
        text-transform: capitalize;
        font-size: $default-font;
        line-height: inherit;
        font-weight: $font-weight-medium;
        color: $color-light-2;
        &:hover {
          background-color: transparent;
          color: $color-light;
          &:after {
            @include transform(scale(1));
            opacity: 1;
            visibility: visible;
          }
        }
        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $color-light;
          @include transition(0.3s);
          @include transform(scale(0));
          opacity: 0;
          visibility: hidden;
          @include border-radius(0);
        }
      }
    }
  }
  .contact-form-action {
    padding-left: 0;
    form {
      .form-group {
        .form-control {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .message-control {
          height: 80px;
        }
        .theme-button {
          background-color: $color-light;
          color: $theme-color;
          width: 100%;
          &:hover {
            background-color: $theme-color-2;
            color: $color-light;
          }
        }
      }
    }
  }
  .working-wrapper {
    padding: 20px 20px;
    border: 1px solid rgba(255,255,255,0.2);
    @include border-radius(20px);
    @include transition(0.3s);
    margin-left: -50px;
    margin-right: 50px;
    color: $color-light-2;
    font-size: $default-font;
    font-weight: $font-weight-medium;
    @media #{$tab_device} {
      margin-left: 0;
      margin-right: 0;
    }
    @media #{$large_mobile} {
      margin-left: 0;
      margin-right: 0;
    }
    @media #{$small_mobile} {
      margin-left: 0;
      margin-right: 0;
    }
    .working__time {
      margin-bottom: 5px;
      span {
        color: rgba(255,255,255,0.8);
        font-weight: $font-weight-semi-bold;
      }
    }
    .workingtime__off {
      margin-top: 24px;
      padding-top: 15px;
      margin-bottom: 0;
      border-top: 1px solid rgba(255,255,255,0.1);
    }
  }
}
.footer-shared.footer-widget {
  padding-left: 36px;
  @media #{$tab_device} {
    padding-left: 0;
  }
  @media #{$large_mobile} {
    padding-left: 0;
  }
  @media #{$small_mobile} {
    padding-left: 0;
  }
}
.footer-shared.footer-widget3 {
  @media #{$tab_device} {
    margin-bottom: 0;
  }
  @media #{$large_mobile} {
    margin-bottom: 0;
  }
  @media #{$small_mobile} {
    margin-bottom: 0;
  }
}
.copyright-content {
  position: relative;
  padding-top: 34px;
  margin-top: 90px;
  border-top: 1px solid rgba(160, 174, 198, 0.1);
  @media #{$tab_device} {
    text-align: center;
  }
  @media #{$large_mobile} {
    text-align: center;
  }
  @media #{$small_mobile} {
    text-align: center;
  }
  @media #{$small_mobile_two} {
    margin-top: 50px;
  }
  .copy__desc {
    font-size: $default-font-2;
    color: $color-light-2;
    font-weight: $font-weight-semi-bold;
    a {
      color: $color-light;
      position: relative;
      display: inline-block;
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 1px;
        bottom: 4px;
        left: 0;
        background-color: $color-light;
        @include transition(0.3s);
      }
      &:hover {
        color: $color-light;
        &:after {
          width: 100%;
        }
      }
    }
    i {
      animation: heartBeat 1000ms infinite linear;
      color: #F67E02;
    }
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: -1px;
    left: 0;
    height: 1px;
    width: 60px;
    background-color: rgba(255, 255, 255, 0.3);
  }
  &:after {
    left: auto;
    right: 0;
  }
}

/*==============================================
    footer-area 2
 ===============================================*/
.footer-area2 {
  background-color: $theme-color;
  padding-top: 115px;
  &:before,
  &:after {
    display: none;
  }
}
