/*====================================================
   package-area
 ====================================================*/
.package-tab-content {
  margin-top: 28px;
  .nav-tabs {
    border: none;
    li {
      float: none;
      display: inline-block;
      margin-bottom: 0;
      a {
        background-color: transparent;
        color: $theme-color;
        border: 1px solid $theme-color;
        font-size: $default-font;
        text-transform: capitalize;
        padding: 12px 30px;
        @include border-radius(30px);
        font-weight: $font-weight-semi-bold;
        @include transition(0.3s);
        margin-right: 5px;
        &.active,
        &:hover {
          background-color: $theme-color-2;
          color: $color-light;
          border-color: $theme-color-2;
        }
      }
    }
  }
  .tab-content {
    margin-top: 89px;
    @media #{$tab_device} {
      margin-top: 20px;
    }
    @media #{$large_mobile} {
      margin-top: 20px;
    }
    @media #{$small_mobile} {
      margin-top: 20px;
    }
    .package-active {
      @include transform(scale(1.1));
      background-color: $color-light;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      @include border-radius(4px);
      @media #{$tab_device} {
        @include transform(scale(1));
      }
      @media #{$large_mobile} {
        @include transform(scale(1));
      }
      @media #{$small_mobile} {
        @include transform(scale(1));
      }
      .price__box {
        margin-top: 27px;
        margin-bottom: 32px;
        .price__price-title {
          margin-bottom: 25px;
        }
      }
      .package__list {
        padding-top: 30px;
        margin-bottom: 31px;
      }
      .theme-button {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
.package-content {
  @include border-radius(4px);
  @include transition(0.3s);
  padding: 45px 0;
  @media #{$tab_device} {
    margin-bottom: 30px;
  }
  @media #{$large_mobile} {
    margin-bottom: 30px;
  }
  @media #{$small_mobile} {
    margin-bottom: 30px;
  }
  .package__title {
    font-size: $display-10;
    text-transform: capitalize;
    color: $theme-color;
    font-weight: $font-weight-semi-bold;
  }
  .price__box {
    margin-top: 25px;
    margin-bottom: 34px;
    span {
      display: block;
    }
    .price__price-title {
      font-size: $display-13;
      color: $theme-color;
      font-weight: $font-weight-bold;
      margin-bottom: 28px;
      sup {
        font-size: $display-12;
        top: 3px;
        vertical-align: top;
      }
    }
    .price__text-text {
      text-transform: capitalize;
      color: $theme-color-4;
      font-size: $default-font;
      font-weight: $font-weight-medium;
      opacity: 0.6;
    }
  }
  .package__list {
    margin-bottom: 35px;
    border-top: 1px solid $color-2;
    padding-top: 32px;
    li {
      color: $theme-color-4;
      font-size: $default-font;
      font-weight: $font-weight-medium;
      padding-bottom: 10px;
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
  .theme-button {
    padding-right: 45px;
    background-color: $color-light;
    color: $theme-color;
    .fa__arrow {
      right: 22px;
    }
    &:hover {
      .fa__arrow {
        right: 19px;
      }
    }
  }
  &:hover {
    .theme-button {
      background-color: $theme-color-2;
      color: $color-light;
    }
  }
}

.package-area {
  padding-top: 197px;
  padding-bottom: 221px;
  position: relative;
  z-index: 1;
  background-color: $color-light-grey;
  overflow: hidden;
  @media #{$tab_device} {
    padding-top: 220px;
    padding-bottom: 80px;
  }
  @media #{$large_mobile} {
    padding-top: 250px;
    padding-bottom: 72px;
  }
  @media #{$small_mobile} {
    padding-top: 215px;
    padding-bottom: 55px;
  }
  .col-lg-4 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &:before,
  &:after {
    position: absolute;
    left: -25%;
    content: "";
    background-color: $color-light;
    width: 150%;
    height: 150px;
    @include transform(rotate(-3deg));
    -moz-transform-origin: 3% 0;
    -webkit-transform-origin: 3% 0;
    -ms-transform-origin: 3% 0;
    -o-transform-origin: 3% 0;
    transform-origin: 3% 0;
    @media #{$small_mobile_two} {
      height: 50px;
    }
  }
  &:before {
    top: 0;
    @media #{$small_mobile} {
      height: 120px;
    }
  }
  &:after {
    bottom: -150px;
    @media #{$small_mobile_two} {
      bottom: -24px;
    }
  }
}

/*====================================================
   package-area2
 ====================================================*/
.package-area2 {
  margin-top: 0;
  padding-top: 113px;
  padding-bottom: 141px;
  margin-bottom: 30px;
  @media #{$tab_device} {
    padding-bottom: 45px;
  }
  @media #{$large_mobile} {
    padding-bottom: 45px;
  }
  @media #{$small_mobile} {
    padding-bottom: 35px;
  }
  &:after,
  &:before {
    display: none;
  }
}