/*====================================================
    input-number-group
 ====================================================*/
.input-number-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
  .input-group-button {
    line-height: 29px;
  }
  .input-number {
    padding: 0 12px;
    outline: none;
    display: block;
    text-align: center;
  }
  .input-number,
  .input-number-decrement,
  .input-number-increment {
    border: 1px solid $color-2;
    height: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: $font-weight-bold;
    font-size: 22px;
    @include border-radius(30px);
    color: $theme-color;
  }
  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    width: 35px;
    text-align: center;
    cursor: pointer;
    @include border-radius(50%);
    font-size: 22px;
    @include transition(0.3s);
    &:hover {
      background-color: $theme-color-2;
      color: $color-light;
      border-color: $theme-color-2;
    }
  }
  .input-number-decrement {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
  .input-number-increment {
    margin-left: 0.5rem;
  }
}

