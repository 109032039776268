/*====================================================
   funfact-area
 ====================================================*/
.funfact-area {
  background-color: $theme-color;
  padding-top: 113px;
  padding-bottom: 120px;
  color: $color-light;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .col-lg-3 {
    @media #{$tab_device} {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .sec-heading {
    margin-bottom: 75px;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/dots2.png");
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }
  .fun-get-text {
    padding-top: 60px;
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    &:before,
    &:after {
      position: absolute;
      content: '';
      top: -1px;
      left: 0;
      height: 1px;
      width: 60px;
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:after {
      left: auto;
      right: 0;
    }
  }
}
.funfact-item {
  position: relative;
  @media #{$tab_device} {
    margin-bottom: 60px;
  }
  @media #{$large_mobile} {
    margin-bottom: 60px;
  }
  @media #{$small_mobile} {
    margin-bottom: 60px;
  }
  .funfact-icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    line-height: 80px;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 18px;
      background-color: rgba(255, 255, 255, 0.1);
      @include border-radius(4px);
      @include transform(translateY(-50%) rotate(-33deg));
      @include transition(0.3s);
    }
    .funfact__icon {
      font-size: $display;
      display: block;
    }
  }
  .funfact__number {
    font-size: $display;
    font-weight: $font-weight-semi-bold;
    display: inline-block;
    margin-top: 45px;
    margin-bottom: 32px;
  }
  .funtact__plus {
    font-size: $primary-font;
    font-weight: $font-weight-medium;
  }
  .funfact__meta {
    font-size: $display-4;
    text-transform: capitalize;
    font-weight: $font-weight-semi-bold;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: $color-light;
    opacity: 0.1;
    @media #{$tab_device} {
      display: none;
    }
    @media #{$large_mobile} {
      display: none;
    }
    @media #{$small_mobile} {
      display: none;
    }
  }
  &:last-child:after {
    display: none;
  }
}
/*====================================================
   funfact-area2
 ====================================================*/
.funfact-area2 {
  position: relative;
  padding-bottom: 144px;
  @media #{$small_mobile_two} {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  .sec-heading {
    .sec__title {
      margin-bottom: 50px;
    }
  }
  .funfact-item {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      width: 15px;
      height: 15px;
      background-color: $color-light;
      @include transform(translateY(-50%));
      @include border-radius(4px);
      @media #{$tab_device} {
        right: auto;
        left: 50%;
        top: auto;
        bottom: -50px;
        @include transform(translateX(-50%));
      }
      @media #{$large_mobile} {
        right: auto;
        left: 50%;
        top: auto;
        bottom: -50px;
        @include transform(translateX(-50%));
      }
      @media #{$small_mobile} {
        right: auto;
        left: 50%;
        top: auto;
        bottom: -50px;
        @include transform(translateX(-50%));
      }
    }
    .counter__simble {
      font-size: $primary-font;
      font-weight: $font-weight-semi-bold;
    }
    .plus__simble {
      font-size: $display;
    }
    .funfact__number {
      font-size: $display-9;
    }
    .funfact__meta {
      opacity: .7;
    }
  }
  .funfact-item3 {
    @media #{$tab_device} {
      margin-bottom: 0;
    }
    @media #{$large_mobile} {
      margin-bottom: 0;
    }
    @media #{$small_mobile} {
      margin-bottom: 0;
    }
    &:after {
      display: none;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50%;
    border-bottom: 30px solid $color-light;
    @include transform(rotate(0.000001deg));
  }
  &:before {
    right: 50%;
    border-right: 125px solid transparent;
  }
  &:after {
    left: 50%;
    border-left: 125px solid transparent;
  }
}