/*======== faq-area ===========*/
.faq-panel {
  .faq-heading {
    margin-top: 20px;
    .faq__title {
      color: $theme-color;
      width: 100%;
      cursor: pointer;
      padding: 20px 30px 18px 30px;
      display: block;
      border: 1px solid $color-3;
      font-weight: $font-weight-semi-bold;
      font-size: $display-2;
      position: relative;
      @include border-radius(4px);
      @include transition(0.3s);
      @media #{$small_mobile_two} {
        padding-right: 20px;
        padding-left: 20px;
      }
      &:hover {
        color: $theme-color-2;
        &:before,
        &:after {
          background-color: $theme-color-2;
        }
      }
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 24px;
        right: 30px;
        width: 2px;
        height: 10px;
        background-color: $color-3;
        z-index: 1;
        @include border-radius(14px);
        @include transition(0.3s);
        @media #{$small_mobile_two} {
          right: 20px;
        }
      }
      &:after {
        @include transform(rotate(90deg));
      }
    }
  }
  .faq-content {
    padding: 20px 30px 25px 30px;
    display: none;
    border-right: 1px solid $color-3;
    border-bottom: 1px solid $color-3;
    border-left: 1px solid $color-3;
    background-color: $color-light;
    @include box-shadow(1px 7px 41px -8px rgba(82, 85, 90, 0.1));
    @include border-radius(0 0 5px 5px);
    @media #{$small_mobile_two} {
      padding-right: 20px;
      padding-left: 20px;
    }
    .faq__desc {
      font-size: $default-font;
      color: $theme-color-4;
      line-height: $text-height-2;
      font-weight: $font-weight-medium;
    }
  }
  &.active {
    .faq-heading {
      .faq__title {
        color: $theme-color-2;
        @include border-radius(4px 4px 0 0);
        &:before {
          @include transform(rotate(90deg));
          background-color: $theme-color-2;
        }
        &:after {
          z-index: -1;
        }
      }
    }
  }
}

.faq-area {
  padding-top: 120px;
  padding-bottom: 65px;
  position: relative;
  z-index: 1;
  @media #{$small_mobile_two} {
    padding-top: 80px;
    padding-bottom: 10px;
  }
  .contact-form-action {
    margin-top: 0;
    @media #{$tab_device} {
      margin-top: 50px;
    }
    @media #{$large_mobile} {
      margin-top: 50px;
    }
    @media #{$small_mobile} {
      margin-top: 50px;
    }
  }
}
.faq-body {
  .button-shared {
    .theme-button {
      i {
        margin-right: 10px;
      }
    }
  }
}
