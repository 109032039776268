.custom-checkbox {
  margin-bottom: 8px;
  label {
    color: $theme-color-4;
    font-size: $default-font;
    font-weight: $font-weight-semi-bold;
  }
  a {
    color: $theme-color-2;
  }
  .pass__desc {
    color: $theme-color-2;
    font-weight: $font-weight-semi-bold;
    @media #{$small_mobile_five} {
      &.pull-right {
        float: none !important;
        text-align: left;
        display: block;
        margin-top: 5px;
      }
    }
  }
  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:checked + label {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid $color-2;
    background: $color-light;
    @include border-radius(2px);
  }
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 1px;
    left: 3px;
    font-size: $default-font-5;
    color: $color-light;
    @include transition(0.3s);
    font-family: FontAwesome,sans-serif;
  }
  input[type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    visibility: hidden;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
  input[type=checkbox]:checked + label:before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}