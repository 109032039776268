* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
input:focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
body {
  font-size: $default-font-2;
  line-height: 25px;
  font-family: $theme-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*==== common-style ======*/
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-margin-lr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-padding-l {
  padding-left: 0 !important;
}
.no-padding-r {
  padding-right: 0 !important;
}
.no-padding-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.no-padding-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.colored-text {
  color: $theme-color-2;
}
.text-black {
  color: $color-dark !important;
}
.text-white {
  color: $color-light !important;
}
.radius-rounded {
  @include border-radius(30px);
}
/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}

@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1200px;
  }
}
/*====================================================
    tooltips
 ====================================================*/

/*===== tooltip right =====*/
.tooltip.right .tooltip-inner {
  font-weight: $font-weight-medium;
  background-color: $theme-color;
  width: 85px;
}
.tooltip.right .tooltip-arrow {
  border-right-color: $theme-color;
}

/*========= preloader ==========*/
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $color-light;
  top: 0;
  left: 0;
  z-index: 99999;
  .loader-ripple {
    position: relative;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    div {
      position: absolute;
      border: 4px solid $theme-color-2;
      opacity: 1;
      border-radius: 50%;
      animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

.new-page-badge {
  display: inline-block;
  padding: 0px 5px;
  margin-top: 2px;
  line-height: 22px;
  background-color: $theme-color-2;
  color: $color-light;
  font-size: $default-font-5;
  @include border-radius(4px);
  float: right;
  font-weight: $font-weight-semi-bold;
}

/*====== divider =======*/
.divider {
  text-align: center;
  .divider__dot {
    display: inline-block;
    width: 10px;
    height: 2px;
    @include border-radius(4px);
    margin: 0 15px;
    background-color: $theme-color;
  }
}

.cta-titles {
  font-size: $default-font;
  color: $color-light;
  font-weight: $font-weight-semi-bold;
  .theme-button {
    @media #{$large_mobile_two} {
      margin-top: 20px;
    }
  }
}
.line-legend {
  padding-top: 25px;
  padding-left: 18px;
  .chart-legend {
    li {
      display: inline-block;
      font-size: $default-font;
      text-transform: capitalize;
      position: relative;
      color: $theme-color;
      margin-left: 30px;
      font-weight: $font-weight-medium;
      span {
        width: 15px;
        height: 15px;
        display: inline-block;
        background-color: $theme-color-3;
        @include border-radius(50%);
        position: absolute;
        left: -22px;
        top: 6px;
        &.legend__two {
          background-color: $theme-color-2;
        }
      }
    }
  }
}

.list-items {
  li {
    font-size: $default-font;
    color: $theme-color;
    position: relative;
    margin-bottom: 10px;
    font-weight: $font-weight-semi-bold;
    padding-left: 20px;
    &:after {
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 9px;
      height: 9px;
      @include border-radius(50%);
      background-color: $theme-color-2;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.portfolio-filter {
  border: 2px solid rgba(103, 114, 134, 0.1);
  @include border-radius(50px);
  padding: 15px 0;
  margin: 26px 0 60px 0;
  li {
    display: inline-block;
    padding: 6px 20px;
    cursor: pointer;
    color: $theme-color-4;
    @include border-radius(30px);
    position: relative;
    z-index: 1;
    @include transition(0.3s);
    font-size: $display-4;
    font-weight: $font-weight-semi-bold;
    text-transform: capitalize;
    @media #{$large_mobile_three} {
      margin-bottom: 10px;
    }
    &.active,
    &:hover {
      background-color: $theme-color-2;
      color: $color-light;
      @include box-shadow(0 6px 30px 0px rgba(246, 107, 93, 0.2));
    }
  }
}

.social-profile {
  li {
    display: inline-block;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    a {
      color: $theme-color-4;
      font-size: $default-font;
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      display: block;
      @include border-radius(50%);
      @include transition(0.3s);
      position: relative;
      background-color: rgba(35, 61, 99, 0.1);
      &:before {
        position: absolute;
        content: '';
        left: 50%;
        top: -3px;
        @include transform( translateX(-50%));
        width: 22px;
        height: 9px;
        @include border-radius(50%);
        background-color: $color-light;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}