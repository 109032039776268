/*===== progress-bar-content =====*/
.progress-bar-content {
  margin-top: 35px;
  .skillbar3 {
    margin-bottom: 0;
  }
}

.skillbar {
  position:relative;
  display:block;
  margin-bottom: 55px;
  width:100%;
  background-color: $color-1;
  height:8px;
  @include border-radius(4px);
  @include transition(0.4s);
  -webkit-transition-property:width, background-color;
  -moz-transition-property:width, background-color;
  -ms-transition-property:width, background-color;
  -o-transition-property:width, background-color;
  transition-property:width, background-color;
  .skillbar-title {
    position:absolute;
    top: -30px;
    left: 0;
    font-weight: $font-weight-bold;
    font-size: $display-4;
    color: $theme-color;
  }
  .skillbar-bar {
    height:8px;
    width:0;
    background-color: $theme-color-2;
    @include border-radius(4px);
  }
  .skill-bar-percent {
    position:absolute;
    right: 0;
    top: -30px;
    font-size: $display-4;
    color: $theme-color;
    font-weight: $font-weight-medium;
  }
}