/*====================================================
   clientlogo-area
 ====================================================*/
.clientlogo-area {
  text-align: center;
  padding-bottom: 30px;
}
.client-logo-item {
  display: inline-block;
}
/*====================================================
   clientlogo-area2
 ====================================================*/
.clientlogo-area2 {
  padding-top: 120px;
  padding-bottom: 92px;
}
/*====================================================
   clientlogo-area3
 ====================================================*/
.clientlogo-area3 {
  padding-top: 10px;
  padding-bottom: 34px;
  @media #{$small_mobile} {
    padding-top: 0;
  }
}
/*====================================================
   clientlogo-area4
 ====================================================*/
.clientlogo-area4 {
  background-color: $color-light-grey;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 15px;
  margin-bottom: 45px;
}
