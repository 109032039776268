/*
	Template Name: Minzel
	Author: techydevs
	Description: Minzel - Business HTML Template
	Tags: one-column, two-columns, three-columns, four-columns, custom-menu, featured-images, translation-ready
	Version: 1.0
*/
/*------------------------------------------------------------------
[Table of contents]
	+ General
		- general Styles
		- common-style
		- Preloader
		- divider
		- Back To Top
	+ Header
		- Logo
		- login
		- sign up
		- language
		- Main Navigation
		- Dropdown
		- Offcanvas menu
		- Search
	+ Content
		+ Main Files
			- index.html
			- index-2.html
	+ Banner Area
			- Breadcrumb
	+ Pages
			- About
			- Case Study
			- Single Case Study
			- Team Grid
			- Team Details
			- Testimonials
			- FAQs
			- Pricing
			- 404 Error Pages
			- Sign Up
			- Login
			- Recover
	+ blog
			- Blog Full Width
			- Blog Grid
			- Blog Detail
	+ Footer
		+ Top Footer
			- Contact Minzel
			- Help & Support
			- Working Hours
			- Get In Touch
		+ Bottom Footer
		    - Copyright
	+ Responsive Design Styles
		    - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1200px)';
            - $laptop_m_four: '(max-width: 1199px)';
            - $laptop_m_five: '(max-width: 1024px)';
            - $laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
            - $medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
            - $ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $tab_device_two: '(max-width: 768px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $large_mobile_two: ' (max-width: 600px)';
            - $large_mobile_three: ' (max-width: 480px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
            - $small_mobile_four: '(max-width: 425px)';
            - $small_mobile_three: '(max-width: 400px)';
            - $small_mobile_five: '(max-width: 375px)';
            - $small_mobile_two: '(max-width: 320px)';
-------------------------------------------------------------------*/

// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixins";
@import "partials/keyframse";
// button scss
@import "partials/button";
// quantity scss
@import "partials/quantity";
// tabs scss
@import "partials/tabs";
// default scss
@import "partials/common";
// back-to-top scss
@import "partials/back-to-top";
// radio-button scss
@import "partials/radio-button";
// pagination scss
@import "partials/pagination";
// menu scss
@import "partials/menu";
// heading scss
@import "partials/heading";
// breadcrumb scss
@import "partials/breadcrumb";
// tags scss
@import "partials/tags";
// comment scss
@import "partials/comment";
// info scss
@import "partials/info-box";
// hero scss
@import "partials/hero";
// feature scss
@import "partials/feature";
// timeline scss
@import "partials/timeline";
// about scss
@import "partials/about";
// progress-bar scss
@import "partials/progress-bar";
// howitworks scss
@import "partials/howitworks";
// funfact scss
@import "partials/funfact";
// card scss
@import "partials/card";
// sidebar scss
@import "partials/sidebar";
// google-map scss
@import "partials/google-map";
// nice-select scss
@import "partials/nice-select";
// blog scss
@import "partials/blog";
// call-to-action scss
@import "partials/call-to-action";
// testimonial scss
@import "partials/testimonial";
// team-member scss
@import "partials/team-member";
// chart scss
@import "partials/chart";
// pricing scss
@import "partials/pricing";
// newsleller scss
@import "partials/newsleller";
// clientlogo scss
@import "partials/clientlogo";
// contact scss
@import "partials/contact";
// service scss
@import "partials/service";
// blockquote scss
@import "partials/blockquote";
// faq scss
@import "partials/faq";
// error scss
@import "partials/error";
// checkbox scss
@import "partials/checkbox";
// portfolio scss
@import "partials/portfolio";
// shop scss
@import "partials/shop";


// footer scss
@import "partials/footer";




