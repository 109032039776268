/*====================================================
   portfolio-area
====================================================*/
.portfolio-list {
  .single-portfolio-item.col-lg-4 {
    @media #{$large_mobile_and_small} {
      width: 50%;
      float: left;
    }
    @media #{$large_mobile_three} {
      width: 100%;
      float: none;
    }
  }
}

.single-portfolio-item {
  .portfolio-inner {
    @include border-radius(4px);
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    .portfolio__img {
      width: 100%;
      @include border-radius(4px);
      @include transition(0.5s);
    }
    .portfolio-hover {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(59, 62, 121, 0.9);
      @include border-radius(4px);
      opacity: 0;
      visibility: hidden;
      @include transition(0.5s);
      .hover-text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        @include transform(translate(-50%, -50%));
        color: $theme-color;
        a {
          display: block;
          color: $color-light;
          h4 {
            font-size: $display-2;
            font-weight: $font-weight-semi-bold;
            margin-bottom: 5px;
          }
          span {
            text-transform: capitalize;
            font-size: $default-font;
            font-weight: $font-weight-medium;
            color: $color-light-2;
          }
          &:hover {
            h4 {
              color: $theme-color-2;
            }
          }
        }
      }
    }
    &:hover {
      .portfolio__img {
        @include transform(scale(1.2));
      }
      .portfolio-hover {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.single-port-img {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid $color-light;
    @include transform(translateX(-50%));
  }
  &:after {
    position: absolute;
    content: '';
    border: 12px solid rgba(35, 61, 99, 0.1);
    height: 100%;
    width: 100%;
    left: -50px;
    bottom: -49px;
    z-index: -1;
    @include border-radius(4px);
  }
  .single__img {
    width: 100%;
    @include border-radius(10px 0 10px 0);
  }
}

.single-port-content {
  margin-top: 30px;
}

.single-port-top {
  border-bottom: 1px solid $color-1;
  padding-bottom: 28px;
  margin-bottom: 29px;
  .single-port-item {
    font-weight: $font-weight-medium;
    .sp__title {
      font-size: $default-font;
      color: $theme-color;
      font-weight: $font-weight-semi-bold;
      text-transform: capitalize;
    }
    .sp__desc {
      color: $theme-color-4;
      i {
        color: $theme-color-2;
        margin-right: 5px;
      }
      a {
        color: $theme-color-4;
        &:hover {
          color: $theme-color-2;
        }
      }
    }
  }
  .single-port-item1,
  .single-port-item2 {
    @media #{$tab_device} {
      margin-bottom: 20px;
    }
    @media #{$large_mobile} {
      margin-bottom: 20px;
    }
    @media #{$small_mobile} {
      margin-bottom: 20px;
    }
  }
  .single-port-item3 {
    @media #{$large_mobile} {
      margin-bottom: 20px;
    }
    @media #{$small_mobile} {
      margin-bottom: 20px;
    }
  }
}
.single-port-bottom {
  margin-bottom: 55px;
  .sp__title {
    font-size: $display-12;
    color: $theme-color;
    font-weight: $font-weight-bold;
    margin-bottom: 28px;
  }
  .sp__desc {
    font-size: $default-font;
    color: $theme-color-4;
    font-weight: $font-weight-medium;
    line-height: $text-height-2;
    margin-bottom: 20px;
  }
}
.project-list {
  margin-bottom: 41px;
  .project-title {
    font-size: $display-10;
    color: $theme-color;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
    margin-bottom: 25px;
  }
  .result-title {
    margin-bottom: 30px;
  }
  .pro-item {
    .list-items {
      li {
        color: $theme-color-4;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
        padding-left: 0;
        &:after {
          display: none;
        }
        i {
          margin-right: 5px;
          color: $theme-color-2;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sp__desc {
      font-size: $default-font;
      color: $theme-color-4;
      font-weight: $font-weight-medium;
      margin-bottom: 20px;
      line-height: $text-height-2;
    }
    .line-legend {
      padding-top: 25px;
      padding-left: 18px;
    }
  }
  .pro-item2 {
    @media #{$tab_device} {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media #{$large_mobile} {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media #{$small_mobile} {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.portfolio-area {
  padding-top: 113px;
  padding-bottom: 104px;
  .col-lg-12.portfolio-column {
    .related-post {
      .sec__title {
        font-size: $display-12;
      }
    }
  }
  .sec-heading {
    .sec__title {
      margin-bottom: 30px;
    }
  }
}
/*====================================================
   single-portfolio-area
====================================================*/
.single-portfolio-area {
  padding-top: 122px;
  padding-bottom: 100px;
  .col-lg-4 {
    @media #{$tab_device} {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
